<template>
    <div v-if="Object.keys(top_spocs_summarys).length > 0">
        <vs-card>
            <!-- <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-type="flex"
                    v-for="(top_spocs_summary,key, i) in top_spocs_summarys" :key="i" vs-w="2.4">
                    <vs-card class="card-shadow">
                        <h6 class="card-header">
                            {{ headers[key]}}
                        </h6>
                        <div class="count" v-for="(item, index) in top_spocs_summary" :key="index">
                            <vs-row class="colss">
                                <vs-col vs-w="9" :id="'id'+index" class="responsive-name-main">
                                    <b>{{ item.spoc_name}}</b>
                                </vs-col>
                                <vs-col vs-w="9" :id="'id'+index" class="responsive-name">
                                    <vs-tooltip :text="item.spoc_name" color="dark">
                                        <b>{{ responsiveName(item.spoc_name) }}</b><p></p>
                                    </vs-tooltip>
                                </vs-col>
                                <vs-col vs-w="3" class="spoc-name" :id="'id'+index">
                                    <vs-tooltip :text="key" color="warning" v-if="item[key] !== undefined">
                                        <vs-chip color="#ffa90e"><b>{{item[key]}}</b></vs-chip><span></span>
                                    </vs-tooltip>
                                    <vs-tooltip :text="air_keys[key]" color="#1ce6cb"
                                        v-if="item[air_keys[key]] !== undefined">
                                        <vs-chip color="#1ce6cb"> <b>{{item[air_keys[key]]}}</b></vs-chip><span></span>
                                    </vs-tooltip>
                                </vs-col>
                            </vs-row>
                        </div>
                    </vs-card>
                </vs-col>
            </vs-row> -->
            <vs-row>
                <vs-col vs-justify="center" vs-align="center" vs-type="flex"
                    v-for="(top_spocs_summary, key, i) in top_spocs_summarys" :key="i" vs-w="2.4" style="padding:0px 6px">
                    <vs-card class="card-shadow">
                        <h6 class="card-header">
                            {{ headers[key] }}
                        </h6>
                        <div class="count" v-for="(item, index) in top_spocs_summary" :key="index">
                            <vs-row vs-w="12" class="colss"  style="margin-top:3px;">
                                <vs-col vs-w="7" vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="7" vs-sm="4" vs-xm="4">
                                    <vs-tooltip :text="item.spoc_name" color="dark">
                                        <b>{{ responsiveName(item.spoc_name,top_spocs_summary) }}</b><p></p>
                                    </vs-tooltip>
                                </vs-col>
                                <vs-col vs-w="3" vs-type="flex" vs-justify="center" vs-align="center" :id="'id'+index" vs-lg="5" vs-sm="8" vs-xm="4">
                                    <vs-tooltip :text="tool_tip_count[key]" color="warning" v-if="item[count[key]] !== undefined">
                                        <vs-chip color="#ffa90e"><b>{{item[count[key]]}}</b></vs-chip><span></span>
                                    </vs-tooltip>
                                    <vs-tooltip :text="tool_tip_air_count[key]" color="#1ce6cb"
                                        v-if="item[air_keys[key]] !== undefined">
                                        <vs-chip color="#1ce6cb"> <b>{{item[air_keys[key]]}}</b></vs-chip><span></span>
                                    </vs-tooltip>
                                </vs-col>
                            </vs-row>
                        </div>
                        <div v-if="!(top_spocs_summary.length > 0)" class="not-found">
                            <!-- <vs-icon icon="query_stats" color="danger"></vs-icon> -->
                            <b>{{no_data[key]}}</b>
                        </div>
                    </vs-card>
                </vs-col>
            </vs-row>
        </vs-card>
    </div>
</template>
<script>


export default {
    props: ["top_spocs_summarys"],
    mounted() {
        console.log("top_spocs_summarys", this.top_spocs_summarys)
    },
    data() {
        return {
            headers: { enrollments: 'Enrollments', visits: 'Visits', connected_calls: 'Connected Calls', visits_utilization: 'Visits Utilization', call_utilization: 'Call Utilization' },
            no_data: { enrollments: 'NO Enrollment Data Found', visits: 'NO Visit Data Found', connected_calls: 'NO Call Data Found', visits_utilization: 'NO Visit Data Found', call_utilization: 'NO Call Data Found' },
            count:{enrollments: 'enrollments_count', visits: 'visit_count', connected_calls: 'connected_calls', visits_utilization: 'visit_utilization', call_utilization: 'call_utilization'},

            air_keys: { enrollments: 'enrollment_air', visits: 'visit_air', connected_calls: 'connected_calls_air', visits_utilization: 'visit_utilization_air', call_utilization: 'call_utilization_air' },
            tool_tip_count:{enrollments: 'Enrollment Count', visits: 'Visit Count', connected_calls: 'Connected Call Count', visits_utilization: 'Visit Utilization %', call_utilization: 'Call Utilization %'},
            tool_tip_air_count:{enrollments: 'Enrollment AIR', visits: 'Visit AIR', connected_calls: 'Connected AIR', visits_utilization: 'Visit Utilization AIR', call_utilization: 'Call Utilization AIR'},
        }
    },
    methods: {
        responsiveName(name,v) {
            console.log(Object.keys(v));
            let index = 0
            let res = []

            while ((index = name.indexOf(' ', index + 1)) > 0) {
                res.push(index);
            }
            index = res[1]
            if (index == undefined) {
                return name
            }
            return name.slice(0, index)
        },
    },
}
</script>
<style scoped>
/* .spoc-name {
    display: flex;
    align-content: center;
    justify-content: flex-end;
} */

/* .colss>#id0 {
    margin-top: 20px;
} */

.count {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    color: #1e1e1e;
    font-weight: 400;
    font-size: 12px;
}

.card-header {
    background: #36a7fc;
    padding: 10px;
    margin: -10px;
    border-radius: 15px;
    color: #ffffff;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
}

.card-main-header {
    background: #0044ba;
    padding: 10px;
    margin: -10px;
    border-radius: 15px 15px 0px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 20px;
    color: #ffffff;
}

.card-shadow {
    width: 110%;
    height: 90%;
}

.responsive-name {
    display: none;
}
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:70px 0px;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 2px;
    color: #bebcbc;
}
@media only screen and (max-width: 1400px) {
    .count {
        font-size: 9px !important;
    }
    .not-found{
    font-size: 11px;
}
}
</style>