<template >
  <div>
    <vs-card class="boxShadow" style="width: 150px; height: 120px">
      <h6 class="card-header">
        {{ capitalize(evaluation_key) }}
      </h6>
      <div v-if="evaluation_value == 0" class="not-found">NO Data Found</div>
      <div
        v-else
        v-on:click="getEvaluationDetails(evaluation_key, evaluation_value)"
        class="count"
      >
        {{ evaluation_value }}
      </div>
    </vs-card>
  </div>
</template>
<script>
export default {
  props: ["evaluation_value", "evaluation_key"],
  data() {
    return {
      evaluation_header: {
        evaluation_submitted: " Submitted Evaluation",
        completed_evaluation: "Evaluation Completed ",
        evaluation_initiated: "Evaluation Initiated",
        evaluation_not_initiated: "Evaluation Not Initiated",
        total_evaluations: "Total Evaluation",
      },
    };
  },
  methods: {
    getEvaluationDetails(type, count) {
      this.$emit("show-evaluation-details", type, count);
    },
    capitalize(val) {
      const str = val.replace(/_/g, " ");
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const str2 = arr.join(" ");
      return str2;
    },
  },
};
</script>
<style scoped>
.not-found{
    font-family: 'Montserrat';
    display: flex;
    justify-content: center;
    text-align: center;
    margin:30px 0px;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 1px;
    color: #bebcbc;
}
.count {
  display: flex;
  justify-content: center;
  margin-top: 5%;
  color: #1e1e1e;
  padding: 10px;
  font-weight: 600;
  color: black;
  text-align: center;
  font-size: 30px;
}
.boxShadow {
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  border-left: 1px solid gray;
  border-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-header {
  display: flex;
  align-items: center;
  height: 43px;
  justify-content: center;
  background: #9666f6;
  padding: 10px;
  margin: -10px;
  border-radius: 15px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  margin: -8%;
}
</style> 